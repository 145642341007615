import router from '@/router'
import store from '@/store'
import { ref, computed } from '@vue/composition-api'
import { UserRoles } from './models/UserRole'

export default function useUserForm() {
  // DATA

  const initialUserFormData = {
    nom: '',
    prenom: '',
    utilisateurRole: UserRoles.FRONTOFFICE,
    email: '',
    clientId: '',
    siteId: '',
    password: '',
    passwordVerification: '',
  }

  const userFormData = ref({ ...initialUserFormData })
  const roleOptions = [
    { label: 'Administrateur', key: UserRoles.ADMIN },
    { label: 'Net&Co', key: UserRoles.BACKOFFICE },
    { label: 'Client', key: UserRoles.FRONTOFFICE },
  ]
  const clientsOptions = ref([])
  const siteOptions = ref([])

  store.dispatch('client/fetchClients').then(response => {
    clientsOptions.value = response.data
  })

  // Methods

  const resetUserdata = () => {
    userFormData.value = { ...initialUserFormData }
  }

  const fetchSite = () => {
    siteOptions.value = [{ id: null, nom: 'Tous les sites', siteType: 'provenance' }]
    store.dispatch('site/fetchSites', userFormData.value.clientId).then(response => {
      const sites = response.data
      siteOptions.value = [...siteOptions.value, ...sites.filter(site => site.siteType === 'provenance')]
    })
  }

  const setUserFormDataFromUid = userId => {
    store.dispatch('users/fetchUserById', userId).then(response => {
      userFormData.value = response.data
      return fetchSite()
    })
  }

  const updateClient = newValue => {
    userFormData.value.clientId = newValue
    userFormData.value.siteId = undefined
    fetchSite()
  }

  // Computed

  const isEditMode = computed(() => router.currentRoute.params.id)

  return {
    roleOptions,
    clientsOptions,
    siteOptions,
    userFormData,
    resetUserdata,
    updateClient,
    setUserFormDataFromUid,
    isEditMode,
  }
}
