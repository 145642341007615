<template>
  <b-card
    no-body
    class="mb-0"
  >
    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        class="p-2 col-md-6 col-xs-12"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >

        <h5>
          {{ title }}
        </h5>

        <!-- Nom -->
        <validation-provider
          #default="validationContext"
          name="Nom"
          rules="required"
        >
          <b-form-group
            label="Nom"
            label-for="nom"
          >
            <b-form-input
              id="nom"
              v-model="userFormData.nom"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              Le champ Nom est requis
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Prenom -->
        <validation-provider
          #default="validationContext"
          name="Prénom"
          rules="required"
        >
          <b-form-group
            label="Prénom"
            label-for="prenom"
          >
            <b-form-input
              id="prenom"
              v-model="userFormData.prenom"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              Le champ Prénom est requis
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Selecteur de rôle-->
        <validation-provider
          #default="validationContext"
          name="Type d'utilisateur"
          rules="required"
        >
          <b-form-group
            label="Type d'utilisateur"
            label-for="role"
          >
            <v-select
              id="role"
              v-model="userFormData.utilisateurRole"
              :options="roleOptions"
              label="label"
              :reduce="role => role.key"
              class="w-100 select-cursor"
              :clearable="false"
              :state="getValidationState(validationContext)"
            ><div slot="no-options">
              Aucune option
            </div></v-select>
            <b-form-invalid-feedback>
              Le champ Type est requis
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!--Selecteur de client quand le rôle est front office -->
        <validation-provider
          v-if="userFormData.utilisateurRole === 'frontoffice'"
          #default="validationContext"
          name="Client"
          rules="required"
        >
          <b-form-group
            label="Client"
            label-for="client"
          >
            <v-select
              id="client"
              :value="userFormData.clientId"
              :options="clientsOptions"
              label="nom"
              :reduce="client => client.id"
              class="w-100 select-cursor"
              :clearable="false"
              :state="getValidationState(validationContext)"
              @input="updateClient"
            ><div slot="no-options">
              Aucune option
            </div></v-select>
            <b-form-invalid-feedback>
              Le champ Client est requis
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!--Selecteur de sites quand le rôle est front office et client sélectionné -->
        <validation-provider
          v-if="userFormData.utilisateurRole === 'frontoffice' && userFormData.clientId"
          #default="validationContext"
          name="Site"
        >
          <b-form-group
            label="Site"
            label-for="site"
          >
            <v-select
              id="site"
              v-model="userFormData.siteId"
              :options="siteOptions"
              label="nom"
              :reduce="site => site.id"
              class="w-100 select-cursor"
              :clearable="false"
              :state="getValidationState(validationContext)"
            ><div slot="no-options">
              Aucune option
            </div></v-select>
            <b-form-invalid-feedback>
              Le champ Site est requis
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Email -->
        <validation-provider
          v-if="!isEditMode"
          #default="validationContext"
          name="Email"
          rules="required|email"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="userFormData.email"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ userFormData.email.length > 0 ? 'Le champ doit contenir un email valide' : 'Le champ Email est requis' }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Mot de passe -->
        <validation-provider
          v-if="!isEditMode"
          #default="{ errors }"
          name="Password"
          rules="required|password"
          vid="password"
        >
          <b-form-group
            label="Mot de passe"
            label-for="password"
          >
            <b-form-input
              id="password"
              v-model="userFormData.password"
              type="password"
              :state="errors.length > 0 ? false:null"
              trim
            />

            <b-form-invalid-feedback>
              {{ !userFormData.password.length ? 'Le champ Mot de Passe est requis' : 'Votre mot de passe doit contenir au moins huit caractères, dont une majuscule, une lettre en minuscule, un chiffre et un caractère spécial' }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Vérification de mot de passe -->
        <validation-provider
          v-if="!isEditMode"
          #default="{ errors }"
          name="Password Verification"
          rules="required|confirmed:password"
        >
          <b-form-group
            label="Mot de passe (à nouveau)"
            label-for="passwordVerification"
          >
            <b-form-input
              id="passwordVerification"
              v-model="userFormData.passwordVerification"
              type="password"
              :state="errors.length > 0 ? false:null"
              trim
            />

            <b-form-invalid-feedback>
              {{ !userFormData.passwordVerification.length ? 'Le champ Vérification De Mot de Passe est requis' : 'Le mot de passe ne correspond pas' }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            {{ submitButtonText }}
          </b-button>
        </div>

      </b-form>
    </validation-observer>
  </b-card>

</template>

<script>
import {
  BCard, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import router from '@/router'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import useUserForm from './useUserForm'

export default {
  components: {
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    submitButtonText: {
      type: String,
      required: true,
    },
    userFormToUpdate: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const {
      roleOptions,
      clientsOptions,
      siteOptions,
      userFormData,
      updateClient,
      resetUserdata,
      setUserFormDataFromUid,
      isEditMode,
    } = useUserForm()

    if (props.userFormToUpdate && router.currentRoute.params.id) {
      setUserFormDataFromUid(router.currentRoute.params.id)
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetUserdata)

    const onSubmit = () => {
      emit('submitUserForm', userFormData.value)
    }

    return {
      onSubmit,
      updateClient,

      refFormObserver,
      getValidationState,
      resetForm,

      roleOptions,
      clientsOptions,
      siteOptions,
      userFormData,
      isEditMode,

    }
  },
}
</script>
